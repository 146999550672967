import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("transaciton-date-modal-controller");

    }


    toggleModal(event) {
        console.log("show modal");
        let modal = document.querySelector('.transaction-date-modal');
        modal.classList.toggle('hidden');
    }


}

