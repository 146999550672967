import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {
    static targets = ["searchResults", "searchItem"];

    connect() {
        console.log("log from searchForm controller");
        if (this.hasSearchItemTarget) {
            this.searchItemTarget.addEventListener("search", function (event) {
                const resultsArea = event.target.closest(".search-container").querySelector(".bl-search-results");
                resultsArea.classList.remove("showing");
                resultsArea.innerHTML = '';
            });
        }
    }

    requestSearch(event) {
        clearTimeout(delayTimer);
        const abortSignal = new AbortController();
        var component = this;
        const resultsArea = event.target.closest('.search-container').querySelector('.bl-search-results');
        if (event.which == 13 || event.keyCode == 13) {
            console.log('aborting Type Ahead search');
            abortSignal.abort();
            resultsArea.classList.remove("showing")
        }
        delayTimer = setTimeout(function () {
          if (component.searchItemTarget.value.length > 2) {
              resultsArea.classList.add("showing");
              fetch("/search/query.html?search=" +
                  encodeURIComponent(component.searchItemTarget.value) +
                  "&type=" + encodeURIComponent(event.target.dataset.scope) +
                  "&more=" + encodeURIComponent(event.target.dataset.more) +
                  "&limit=" + encodeURIComponent((event.target.dataset.limit || 5)) +
                  "&excluded=" + encodeURIComponent((event.target.dataset.excludedIds || "[]")),
                  {abortSignal})
                  .then(response => {
                      return response.text();
                  })
                  .then(html => {
                      resultsArea.innerHTML = html;
                  }).catch(error => {
                  console.warn(error);
                  document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + error.message + "</p>";
              })
              ;
          } else {
              resultsArea.classList.remove("showing")
          }

        }, 1100)

  }

}
