import {Controller} from "stimulus";

var delayTimer;

export default class extends Controller {
    static targets = ["searchField", "searchResults", "searchItem", "selectedId", "selectedType", "loadingIndicator"];

    connect() {
        console.log("PolymorphicSearchController connected");
    }

    requestSearch(event) {
        clearTimeout(delayTimer);
        const searchQuery = this.searchFieldTarget.value.trim();
        const resultsArea = this.searchResultsTarget;

        // Hide the dropdown if the query is too short or Enter is pressed
        if (event.keyCode === 13 || searchQuery.length < 3) {
            resultsArea.classList.add("hidden");
            resultsArea.innerHTML = "";
            return;
        }

        // Debounce search input
        delayTimer = setTimeout(() => {
            this.performSearch(searchQuery, resultsArea);
        }, 300); // 300ms debounce delay
    }

    performSearch(query, resultsArea) {
        const polymorphicTypes = this.data.get("types"); // e.g., "Contact,Business"
        const endpoint = `/search/query.json?search=${encodeURIComponent(query)}&polymorphic=${encodeURIComponent(polymorphicTypes)}&limit=5`;

        this.showLoadingIndicator();

        fetch(endpoint)
            .then((response) => response.json())
            .then((results) => {
                resultsArea.innerHTML = this.buildResults(results);
                resultsArea.classList.remove("hidden");
            })
            .catch((error) => {
                console.error("Error performing search:", error);
            })
            .finally(() => {
                this.hideLoadingIndicator();
            });
    }

    buildResults(results) {
        if (!results || Object.keys(results).length === 0) {
            return `<div class="no-results px-4 py-2 text-gray-500">No matches found.</div>`;
        }

        const resultElements = [];

        // Iterate over all polymorphic result types dynamically
        Object.keys(results).forEach((type) => {
            const groupItems = results[type];

            if (groupItems && groupItems.length > 0) {
                resultElements.push(
                    `<div class="px-4 py-2 text-gray-700 font-semibold border-b border-gray-200">
          ${type.charAt(0).toUpperCase() + type.slice(1)}
        </div>` +
                    groupItems.map((item) => `
            <div class="search-item px-4 py-2 hover:bg-gray-100 cursor-pointer"
                 data-id="${item.searchable_id}" 
                 data-type="${item.searchable_type}" 
                 data-action="click->polymorphic-search#selectResult">
              ${item.content} (${item.searchable_type})
            </div>
          `)
                        .join("")
                );
            }
        });

        return resultElements.join("");
    }

    selectResult(event) {
        const selectedElement = event.currentTarget;
        const selectedId = selectedElement.dataset.id;
        const selectedType = selectedElement.dataset.type;

        // Update hidden fields with the selected polymorphic data
        this.selectedIdTarget.value = selectedId;
        this.selectedTypeTarget.value = selectedType;

        // Optionally, update visible input with the selected name
        this.searchFieldTarget.value = selectedElement.textContent.trim();

        // Hide and clear dropdown results
        this.searchResultsTarget.classList.add("hidden");
        this.searchResultsTarget.innerHTML = "";
    }

    showLoadingIndicator() {
        if (this.hasLoadingIndicatorTarget) {
            this.loadingIndicatorTarget.classList.remove("hidden");
        }
    }

    hideLoadingIndicator() {
        if (this.hasLoadingIndicatorTarget) {
            this.loadingIndicatorTarget.classList.add("hidden");
        }
    }
}